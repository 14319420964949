import { Component, Inject } from '@angular/core';
import { LocalDataService } from '../../services/utility/local-data.service';
import { IUWPipelineErrorResponse } from './IUWPipelineErrorResponse';
import { IVisualStudioValidationErrorResponse } from './IVisualStudioValidationErrorResponse';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RoutesEnum, RoutingService } from '@Services';
import { AuthService } from '@auth0/auth0-angular';

// This is just view model to make it easy for us to map different error response to the view
interface IErrorNotification {
  title: string;
  errors: string[];
  stackTrace: string;
  correlationId: string;
}


@Component({
    selector: 'error-notification',
    templateUrl: 'error-notification.component.html',
    styleUrls: ['error-notification.component.scss']
})
export class ErrorNotificationComponent {
    show = false;
    isAdmin = false;
    model: IErrorNotification;
    public error$: Observable<Error>;
    
    constructor(
    public localDataService: LocalDataService,
    public clipboard: Clipboard,
    private auth: AuthService,
    private routingService: RoutingService,
    private dialogRef: MatDialogRef<ErrorNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        const userRole = this.localDataService.getUserRoleName();
        this.isAdmin = (userRole === 'Admin');

        this.error$ = this.auth?.error$;
    }

    //#region Helpers

    private isUWPipelineException = () =>
        this.data.hasOwnProperty('data') && this.data.hasOwnProperty('correlationId');

    private isVisualStudioValidationException = () =>
        this.data.hasOwnProperty('errors') && this.data.hasOwnProperty('traceId') && this.data.hasOwnProperty('type');

    private mapVisualStudioValidationException(): IErrorNotification {
        const data = this.data as IVisualStudioValidationErrorResponse;
        const errors: string[] = [];

        for(const propertyName in data.errors)
            errors.push(...data.errors[propertyName]);

        return { title: data.title, errors, stackTrace: '', correlationId: '' };
    }
  
    private mapUWPipelineToErrorNotification(): IErrorNotification {
        const data = this.data as IUWPipelineErrorResponse;
        return { ...data, title: data.sourceError, errors: data.userErrors };
    }
  
    private mapDataToErrorNotification() {
        if (this.isVisualStudioValidationException()) return this.mapVisualStudioValidationException();
    
        return this.mapUWPipelineToErrorNotification();
    }

    //#endregion
    //#region LifeCycle

    ngOnInit() {
        this.model = this.mapDataToErrorNotification();

        if (this.error$) {
            this.error$.subscribe(err => {
                console.log(JSON.stringify(err));
                this.dialogRef?.close();
                this.routingService?.navigateToRoute(RoutesEnum.invalidAuth, {error: err.message}, {error: err.message});
            });
        }
    }

    //#endregion
    //#region Handlers

    copyCorrelationIdToClipBoard() {
        this.clipboard.copy(this.model.correlationId);
    }

    //#endregion
}
