import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WhiteLabelService } from '@Services';

@Component({
    selector: 'app-invalid-authorization',
    host: { 'class': 'content' },
    templateUrl: './invalid-authorization.component.html',
    styleUrls: ['../navigation/navigation.component.scss']
})
export class InvalidAuthorizationComponent implements OnInit{

    error: 'Your IP address has been denied.  Please try again.';
    constructor(public router: Router,
        public whitelabelService: WhiteLabelService,
        public activatedRoute: ActivatedRoute
    ){
        this.activatedRoute.params.subscribe(params => {
            this.error = params.error;
        });
    }

    async ngOnInit() {
        const currentTheme = await this.whitelabelService.getCurrentTheme();

        this.whitelabelService.applyTheme(currentTheme);        
    }
}
