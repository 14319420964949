<div id="main">
    <div id="header">
        <i aria-hidden="true" style="margin-left: 20px"></i>
        <div class="logo-container mr-2"></div>
        <div class="flex-1"></div>
    </div>

    <div class="flex flex-column" style="align-items: center; ">
        <div style="margin-top:100px">
            <h1 style="font-weight: 700 !important">Access Denied</h1>
        </div>
        <div style="margin-top: 18px">
            <h5 style="font-weight: 700 !important">{{error}}
            </h5>
        </div>
        <div style="margin-top: 28px">
        </div>
    </div>
</div>