import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FeatureToggle } from 'app/enums/feature-toggle.enum';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import { RoutesEnum, RoutingService } from '../services/routing.service';
import { WhiteLabelService } from '../services/utility/whitelabel.service';
import { catchError, of } from 'rxjs';

@Component({
    selector: 'app-authorization',
    templateUrl: './authorization.component.html',
    styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {
    
    useAuthV2: boolean;
    useNewLoginFormat: boolean;
    isAuthenticated: boolean;
    isLegacy: boolean;
    showContent = false;
    connection: string = null;
    action: string = null;

    constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    protected routingService: RoutingService,
    public whitelabelService: WhiteLabelService,
    public featureManagerService: FeatureManagerService,
    public activatedRoute: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.useAuthV2 = this.featureManagerService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;
        this.useNewLoginFormat = this.featureManagerService.getByName(FeatureToggle.GlobalUseLoginNewFormat).enabled;

        if (!this.useAuthV2) {
            this.routingService.navigateToRoute(RoutesEnum.login);
        }

        this.action = this.activatedRoute.snapshot.queryParams["action"];
        this.connection = this.activatedRoute.snapshot.queryParams['connection'];
        

        if (this.connection){
            const redirectTo = this.activatedRoute.snapshot.queryParams['redirectTo'];
            if (redirectTo){
                localStorage.setItem('uwpipeline.redirectTo', redirectTo);
            }
        }

        const currentTheme = await this.whitelabelService.getCurrentTheme();

        if(currentTheme.connection) {  // whitelabel configuration overrides ?connection= query string parameter
            this.connection = currentTheme.connection;
        }

        this.whitelabelService.applyTheme(currentTheme);

        this.authService.isAuthenticated$.subscribe(result => {
            if (result) {
                this.routingService.navigateToRoute(RoutesEnum.home);
            }
            else {
      
                if(this.connection && this.action !== "logout") {
                    this.signIn();
                } else {

                    const errorMessage = this.activatedRoute.snapshot.queryParams["error_description"];
                    if (errorMessage && errorMessage !== null){
                        this.routingService?.navigateToRoute(RoutesEnum.invalidAuth, {error: errorMessage}, {error: errorMessage});
                    }else{
                        this.showContent = true;
                    }
                }

            }
        });
    }

    signIn() {
        localStorage.setItem('legacy', 'false');
        this.authService.loginWithRedirect({
            authorizationParams: {
                connection: this.connection,
                display: 'page',
                redirect_uri: window.location.origin + '/authorize' 
            }}).pipe(
            catchError(error => {
                console.error('Login error:', error);
                return of(null); // Return an empty observable to avoid further issues
            }))
            .subscribe();
    }

    signOut() {
        this.authService.logout({ 
            logoutParams: {
                returnTo: `${window.location.replace(window.location.origin + '/authorize?action=logout')}`
            }});
    }
}
